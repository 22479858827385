import React, { Component } from "react";
import ReactTable from "react-table-v6";
import {Grid, Container, Header, Button, Icon} from "semantic-ui-react";
import ContingentButton from "../../ContingentButton";

class URLTableGrid extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedKey: ""
        };

        this.state[props.keyName] = "";
    }

    render() {
        let that = this;
        let { keyName, changeSelectedItem, changeAction, changeItemIndex } = this.props;
        return (
            <Container fluid>
                <Grid>
                    <Grid.Column floated='left' width={8}>
                        <Header>{this.props.name}</Header>
                    </Grid.Column>
                    <Grid.Column floated='right' width={8}>
                        <Container fluid textAlign="right">
                            <Button.Group size="mini">
                                <ContingentButton
                                    onClick={this.props.addItem}
                                    service={this.props.service}
                                    module={this.props.module}
                                    scope="all"
                                    user={this.props.user}
                                    allPermissions={this.props.permissions}
                                ><Icon name="plus"/>&nbsp;</ContingentButton>
                                <Button.Or text="" />
                                <ContingentButton
                                    onClick={this.props.deleteItem}
                                    service={this.props.service}
                                    module={this.props.module}
                                    scope="all"
                                    user={this.props.user}
                                    allPermissions={this.props.permissions}
                                >&nbsp;<Icon name="minus"/></ContingentButton>
                            </Button.Group>
                        </Container>
                    </Grid.Column>
                </Grid>
                <ReactTable
                    data={this.props.data}
                    defaultPageSize={3}
                    columns={this.props.columns}
                    loading={this.props.loading}
                    className="-striped -highlight"
                    getTrProps={(state, rowInfo, column, instance) => {
                        return {
                            onClick(event, handleOriginal) {
                                that.setState({selectedKey: rowInfo.row[keyName], newClicked: false}, () => {
                                    changeAction("Modify");
                                    changeSelectedItem(rowInfo.row._original);
                                    changeItemIndex(rowInfo.row._index);

                                });
                            },
                            style: {
                                background: rowInfo && rowInfo.original && that.props.selectedItem === rowInfo.original ? 'rgba(65, 83, 175, .5)' : '',
                            }
                        }
                    }}
                />
            </Container>
        );
    }
}

export default URLTableGrid;
