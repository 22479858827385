import React, {useEffect, useState} from "react";
import {Grid, Dropdown, Form, Input, Button, Message} from "semantic-ui-react";

export default function RelaysReprocessTab(props) {
    const [jobId, setJobId] = useState("");
    const [jobIdOptions, setJobIdOptions] = useState([]);
    const [airingId, setAiringId] = useState("");
    const [resultState, setResultState] = useState(null);
    const [resultMessage, setResultMessage] = useState("");

    useEffect(() => {
        props.RelaysDataProvider.getJobs().then(jobs => {
            const jobIds = [];
            for (let job of jobs) {
                if (job.type === "vod") {
                    jobIds.push({key: job._id, text: job._id, value: job._id});
                }
            }
            console.log("(RelaysReprocessTab.refreshJobs) jobs: ", jobs);
            setJobIdOptions(jobIds);
        });
    }, []);

    const reprocessClicked = () => {
        new Promise(resolve => {
            setResultState("reprocessing");
            setResultMessage(`${jobId}/${airingId} is being reprocessed.`);
            resolve();
        }).then(() => {
            props.RelaysDataProvider.reprocess(jobId, airingId).then(result => {
                setResultState("success");
                setResultMessage(`${jobId}/${airingId}: ${result.message}`);
            }).catch(error => {
                setResultState("error");
                setResultMessage(`${jobId}/${airingId}: ${error.toString()}`);
            });
        });
    }

    return (
        <Grid>
            <Grid.Column width={16}>
                <Grid.Row>
                    <Grid.Column width={16} className="masterContainer">
                        <Message
                            hidden={resultState == null}
                            error={resultState === "error"}
                            success={resultState === "success"}
                            warning={resultState === "reprocessing"}
                            content={resultMessage}
                        />
                        <Form>
                            <Form.Group widths="equal">
                                <Form.Field
                                    control={Dropdown}
                                    label="Job ID"
                                    required
                                    value={jobId}
                                    clearable
                                    fluid
                                    selection
                                    options={jobIdOptions}
                                    onChange={(event, {value}) => setJobId(value)}
                                />
                                <Form.Field
                                    control={Input}
                                    label="Airing ID"
                                    required
                                    value={airingId}
                                    onChange={(event, {value}) => setAiringId(value)}
                                />
                                <Form.Field>
                                    <label>&nbsp;</label>
                                    <Button onClick={reprocessClicked}>Reprocess</Button>
                                </Form.Field>
                            </Form.Group>
                        </Form>
                    </Grid.Column>
                </Grid.Row>
            </Grid.Column>
        </Grid>
    );
};
