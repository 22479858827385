import React, {useState, useEffect} from "react";
import {Button, Container, Form, Modal, Select} from "semantic-ui-react";

export default function RelaysTemplateSelectModal(props) {
    const [open, setOpen] = useState(false);
    const [templateOptions, setTemplateOptions] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState("");

    useEffect(function updateTemplateOptions() {
        const updatedTemplateOptions = [];
        console.log(props.templates);
        for (const template of Object.keys(props.templates)) {
            console.log(template);
            updatedTemplateOptions.push({key: template, text: template, value: template});
        }
        setTemplateOptions(updatedTemplateOptions);
    }, [JSON.stringify(props.templates)]);

    useEffect(function onNewClicked() {
        if (props.newClicked) {
            setOpen(true);
        }
    }, [props.newClicked]);

    const cancelClicked = () => {
        setOpen(false);
        props.setNewClicked(false);
        props.setSelectedTemplate("");
    };

    const createJobClicked = () => {
        setOpen(false);
        props.setSelectedTemplate(selectedTemplate);
    };

    return (
        <Modal
            onClose={() => setOpen(true)}
            open={open}
        >
            <Modal.Header>Select Template</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Container text>When new jobs are created, they must be built from a template. Please select the template that this will be built from.</Container>
                    <Form>
                        <Form.Group widths="equal">
                            <Form.Field
                                label="Template"
                                control={Select}
                                value={selectedTemplate}
                                options={templateOptions}
                                onChange={(event, {value}) => setSelectedTemplate(value)}
                                fluid
                            />
                        </Form.Group>
                    </Form>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button color="blue" onClick={() => createJobClicked()}>Create Job</Button>
                <Button color="black" onClick={() => cancelClicked()}>Cancel</Button>
            </Modal.Actions>
        </Modal>
    );
};
