import React, {Component} from "react";
import JSONEditor from "jsoneditor";
import {Button, Form, Grid, Select, Icon} from "semantic-ui-react";

export default class RelaysMetadataTab extends Component {
    constructor(props) {
        super(props);

        this.state = {
            metadata: {},
            currentEditorText: {},
            lastEditorText: {},
            selectedAttribute: "",
            attributeOptions: []
        };
    }

    componentDidMount() {
        const options = {
            mode: 'text',
            onChange: () => {
                const text = this.jsoneditor.get();
                if (text !== this.state.currentEditorText) {
                    this.setState({currentEditorText: text});
                }
            }
        };
        this.jsoneditor = new JSONEditor(this.container, options);
        this.jsoneditor.set(this.state.currentEditorText);

        this.refreshMetadata();

        if (this.state.metadata) {
            this.generateAttributeOptions();
        }
    }

    generateAttributeOptions = () => {
        this.setState({attributeOptions: Object.keys(this.state.metadata).map(option => {
                return {key: option, value: option, text: option};
            })
        }, () => {
            console.log("setting attribute options to ", this.state.attributeOptions, "from metadata", this.state.metadata);
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log(`(RelaysMetadataTab componentDidUpdate) Started.`, prevProps, prevState, this.props, this.state);
        if (this.state.metadata !== prevState.metadata) {
            this.generateAttributeOptions();
        }

        if (this.state.selectedAttribute !== prevState.selectedAttribute) {
            const text = this.state.metadata.hasOwnProperty(this.state.selectedAttribute) ? this.state.metadata[this.state.selectedAttribute] : {};
            this.setState({currentEditorText: text, lastEditorText: text}, () => {
                this.jsoneditor.set(this.state.currentEditorText);
            });
        }
    }

    refreshMetadata = () => {
        this.props.RelaysDataProvider.getMetadata().then(response => {
            this.setState({metadata: response});
        })
    };

    saveMetadata = () => {
        this.setState({lastEditorText: this.state.currentEditorText});
        this.props.RelaysDataProvider.setMetadata(this.state.selectedAttribute, this.state.currentEditorText).then(response => {
            this.props.toast("Success", "Metadata updated.");
            console.log(response);
            this.refreshMetadata();
        })
    };

    resetValue = () => {
        this.setState({currentEditorText: this.state.lastEditorText}, () => {
            this.jsoneditor.set(this.state.currentEditorText);
        });
    };

    render() {
        return (
            <Grid>
                <Grid.Column className="masterContainer">
                    <Grid.Row style={{paddingBottom: "3ch"}}>
                        <h4>Select Attribute</h4>
                        <Select
                            fluid
                            options={this.state.attributeOptions}
                            value={this.state.selectedAttribute}
                            onChange={(event, {value}) => this.setState({selectedAttribute: value})}
                            onAddItem={(event, {value}) => this.setState({metadata: Object.assign({}, this.state.metadata, {[value]: {}})})}
                            search
                            allowAdditions
                        />
                    </Grid.Row>
                    <Grid.Row>
                        <h4>Attribute Data</h4>
                        <div style={{height: "57vh"}} ref={elem => this.container = elem}/>
                    </Grid.Row>
                    <Grid.Row style={{paddingTop: "3ch"}}>
                        <Button onClick={this.saveMetadata} primary disabled={!this.state.selectedAttribute}>Save Metadata</Button>
                        <Button onClick={this.resetValue} disabled={!this.state.selectedAttribute}>Reset Value</Button>
                    </Grid.Row>
                </Grid.Column>
            </Grid>
        );
    }
}
