import React, {useState, useEffect} from "react";
import {Button, Form, Grid, Input, Icon, List, Card, Message} from "semantic-ui-react";

export default function RelaysTemplateSelector(props) {
    const [filteredTemplates, setFilteredTemplates] = useState([]);
    const [filter, setFilter] = useState("");

    useEffect(function initTemplates() {
        props.refreshTemplates();
    }, []);

    useEffect(function updateFilteredTemplates() {
        const allTemplates = [];
        console.log(props.templates);
        if (Object.keys(props.templates).length > 0) {
            for (const key of Object.keys(props.templates)) {
                console.log(props.templates[key]);
                allTemplates.push(Object.assign({}, props.templates[key], {_id: key}));
            }
        }

        if (allTemplates.length > 0) {
            const updatedFilteredTemplates = [];
            for (const template of allTemplates) {
                console.log("RelaysTemplateSelector.updateFilteredTemplates template: ", template);
                const idMatched = template.hasOwnProperty("_id") && template._id.toString().toLowerCase().includes(filter);
                const sourceMatched = template.hasOwnProperty("source") && template.source.hasOwnProperty("value") && template.source.value.toString().toLowerCase().includes(filter.toLowerCase());
                const typeMatched = template.hasOwnProperty("type") && template.type.hasOwnProperty("value") && template.type.toString().toLowerCase().includes(filter.toLowerCase());
                if (idMatched || sourceMatched || typeMatched) {
                    updatedFilteredTemplates.push(template);
                }
            }

            setFilteredTemplates(updatedFilteredTemplates);
        } else {
            setFilteredTemplates(allTemplates);
        }

    }, [filter, JSON.stringify(props.templates)]);

    const formatProperty = (key, value) => {
        return <span><b>{key}</b>: {value}</span>

    };

    return (
        <Grid>
            <Grid.Column width={16}>
                <Grid.Row>
                    <Form>
                        <Form.Group>
                            <Form.Field>
                                <Button positive icon onClick={() => props.setNewClicked(true)}><Icon name="plus"/>Create New Template</Button>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <Form.Field
                                control={Input}
                                label="Template Name"
                                value={filter}
                                placeholder="Filter Templates"
                                onChange={(event, {value}) => setFilter(value)}
                            />
                            <Form.Field>
                                <label>&nbsp;</label>
                                <Button onClick={() => props.refreshTemplates()} icon primary size="medium">&nbsp;<Icon name="refresh"/></Button>
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </Grid.Row>
                <Grid.Row>
                    {
                        props.loadingTemplates ?
                            <Message icon color="yellow">
                                <Icon loading name="spinner" />
                                <Message.Content>Loading Templates...</Message.Content>
                            </Message> :
                        props.loadingErrorMessage.length > 0 ?
                            <Message>{props.loadingErrorMessage}</Message> : ""
                    }
                    <List style={{maxHeight: "67vh", overflowY: "auto"}}>
                        {
                            filteredTemplates.map(template =>
                                <List.Item
                                    key={template._id}
                                    onClick={() => {
                                        console.log(template);
                                        props.setNewClicked(false);
                                        props.setSelectedTemplateId(template._id);
                                    }}
                                    active={props.selectedTemplateId === template._id}
                                >
                                    <List.Content>
                                        <Card>
                                            <Card.Content>
                                                <Card.Description>
                                                    {template.source ? formatProperty("Source", template.source.value) : ""}
                                                    <br />
                                                    {template.type ? formatProperty("Type", template.type.value) : ""}
                                                </Card.Description>
                                            </Card.Content>
                                        </Card>
                                    </List.Content>
                                </List.Item>
                            )
                        }
                    </List>
                </Grid.Row>
            </Grid.Column>
        </Grid>
    );
};
