import React, {useState, useEffect} from "react";
import {Button, Grid, Message, Table, Container, Icon} from "semantic-ui-react";
import MetadataTableRow from "../../MetadataTable/MetadataTableRow";

const DATA_TYPES = [
    {key: "blank", text: "", value: ""},
    {key: "boolean", text: "Boolean", value: "boolean"},
    {key: "array", text: "Array", value: "array"},
    {key: "number", text: "Number", value: "number"},
    {key: "object", text: "Object", value: "object"},
    {key: "string", text: "String", value: "string"},
];

export default function RelaysJobDetailView(props) {
    const [jobProperties, setJobProperties] = useState([]);
    const [disableSaving, setDisableSaving] = useState(false);

    useEffect(function formatJobProperties() {
        const updatedJobProperties = [];
        if (props.selectedJob) {
            for (const key of Object.keys(props.selectedJob)) {
                const property = props.selectedJob[key];
                let value = property.hasOwnProperty("value") ? property.value : property;
                let type;
                if (property.hasOwnProperty("type"))  {
                    type = property.type;
                } else {
                    type = Array.isArray(value) ? "array" : typeof value;
                }
                let isLocked = property.hasOwnProperty("isLocked") && property.isLocked === true;
                let lockType = property.hasOwnProperty("lockType") && property.lockType === true;
                let highlight = property.hasOwnProperty("highlight") ? property.highlight : true;

                if (type === "array" && !Array.isArray(value)) {
                    value = [value];
                }
                updatedJobProperties.push({
                    key: key,
                    type,
                    value,
                    isLocked,
                    lockType,
                    highlight
                });
            }
        }
        setJobProperties(updatedJobProperties);
    }, [JSON.stringify(props.selectedJob)]);

    const updatePropertyKey = (index, key) => {
        if (index < jobProperties.length) {
            console.log(`RelaysJobDetailView.updatePropertyKey setting key to ${key} for index ${index}`);
            setJobProperties(jobProperties.slice().map((property, i) => {
                return i !== index ? property : {
                    key,
                    value: property.value,
                    type: property.type,
                    isLocked: property.isLocked,
                    lockType: jobProperties[i].lockType,
                    highlight: jobProperties[i].highlight
                }
            }));
        } else {
            console.log(`RelaysJobDetailView.updatePropertyKey attempted to update property with index ${index} when there are only ${jobProperties.length} properties`);
        }
    };

    const updatePropertyValue = (index, value, propertyType) => {
        if (index < jobProperties.length) {
            const updatedJobProperties = [];
            for (let i = 0; i < jobProperties.length; i++) {
                if (i === index) {
                    const currentType =
                        Array.isArray(jobProperties[i].value) ? "array" :
                        [true, false].includes(jobProperties[i].value) ? "boolean" :
                        typeof jobProperties[i] === "string" ? "string" :
                        typeof jobProperties[i];
                    if (currentType === propertyType) {
                        console.log(`RelaysJobDetailView.updatePropertyValue setting property to ${JSON.stringify(value)} for key ${jobProperties[index].key}`);
                        updatedJobProperties.push({
                            key: jobProperties[i].key,
                            value,
                            type: propertyType,
                            isLocked: jobProperties[i].isLocked,
                            lockType: jobProperties[i].lockType,
                            highlight: jobProperties[i].highlight
                        });
                    } else {
                        switch (propertyType) {
                            case "array": value = [value]; break;
                            case "object": value = {"": value}; break;
                            case "number": value = parseFloat(value); break;
                            case "boolean": value = value.toString() !== false; break;
                            case "string": value = value.toString(); break;
                            default: console.log("unexpected property type: ", propertyType);
                        }

                        console.log(`RelaysJobDetailView.updatePropertyValue updating property type to ${propertyType} from ${currentType}, setting property to ${JSON.stringify(value)} for key ${jobProperties[index].key}`);

                        updatedJobProperties.push({
                            key: jobProperties[i].key,
                            value,
                            type: propertyType,
                            isLocked: jobProperties[i].isLocked,
                            lockType: jobProperties[i].lockType,
                            highlight: jobProperties[i].highlight
                        });
                    }
                } else {
                    updatedJobProperties.push(jobProperties[i]);
                }
            }
            setJobProperties(updatedJobProperties);
        } else {
            console.log(`RelaysJobDetailView.updatePropertyValue attempted to update property with index ${index} when there are only ${jobProperties.length} properties`);
        }
    };

    const addProperty = () => {
        const updatedJobProperties = jobProperties.slice();
        updatedJobProperties.push({key: "", value: "", type: "string", isLocked: false, lockType: false, highlight: true});
        setJobProperties(updatedJobProperties);
    };

    const removeProperty = index => {
        const updatedJobProperties = jobProperties.slice();
        updatedJobProperties.splice(index, 1);
        console.log("updated job properties: ", updatedJobProperties)
        setJobProperties(updatedJobProperties);
    };

    return (
        <Grid>
            <Grid.Row style={{height: "83vh", overflowY: "auto"}}>
                <Grid.Column width={16}>
                    {
                        Object.keys(jobProperties).length < 1 && !props.newClicked ?
                            <Message icon color="yellow">
                                <Icon name="exclamation" />
                                <Message.Content>
                                    Please select a job to see and modify its properties.
                                </Message.Content>
                            </Message> :
                            <Container fluid>
                                <Button primary onClick={() => props.onClickSave(jobProperties)} disabled={disableSaving}><Icon name="save outline"/>Save</Button>
                                <Button primary inverted onClick={() => props.setDuplicateClicked(true)}><Icon name="code branch"/>Duplicate</Button>
                                {
                                    props.savingJob ?
                                        <Message icon color="blue">
                                            <Icon loading name="spinner" />
                                            <Message.Content>
                                                Saving job...
                                            </Message.Content>
                                        </Message> :
                                    props.saveJobSuccessMessage.length > 0 ?
                                        <Message icon color="green">
                                            <Icon name="check circle" />
                                            <Message.Content>
                                                The job with id {props.selectedJob._id.value} has been saved.
                                            </Message.Content>
                                        </Message> :
                                    props.saveJobErrorMessage.length > 0 ?
                                        <Message icon color="red">
                                            <Icon name="exclamation circle" />
                                            <Message.Content>
                                                {props.saveJobErrorMessage}
                                            </Message.Content>
                                        </Message> : ""
                                }
                                <Table verticalAlign="top" compact>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell width={1} />
                                            <Table.HeaderCell width={3}>Key</Table.HeaderCell>
                                            <Table.HeaderCell width={3}>Type</Table.HeaderCell>
                                            <Table.HeaderCell width={9}>Value</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {
                                            jobProperties.map((property, index) =>
                                                <MetadataTableRow
                                                    key={`property-key-${index}`}
                                                    index={index}
                                                    property={property}
                                                    updatePropertyKey={updatePropertyKey}
                                                    updatePropertyValue={updatePropertyValue}
                                                    removeProperty={removeProperty}
                                                    DATA_TYPES={DATA_TYPES}
                                                    newClicked={props.newClicked}
                                                    duplicateClicked={props.duplicateClicked}
                                                    idList={props.idList}
                                                    setDisableSaving={setDisableSaving}
                                                />
                                            )
                                        }
                                    </Table.Body>
                                    <Table.Footer fullWidth>
                                        <Table.Row>
                                            <Table.Cell colSpan="4">
                                                <Button fluid onClick={() => addProperty()}>Add Item</Button>
                                            </Table.Cell>
                                        </Table.Row>
                                    </Table.Footer>
                                </Table>
                            </Container>
                    }
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};
