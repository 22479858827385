import React, {Component} from 'react';
import {Button, Form, Header, Checkbox, Input} from "semantic-ui-react";
import Provider from '../../../Services/OriginCDNMapperDataProvider';
import ContingentButton from "../../ContingentButton";

class OriginCDNForm extends Component {
    UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
        if (nextProps.selectedKey && nextProps.selectedKey !== this.props.selectedKey) {
            if (!nextProps.newClicked) {
                Provider.get(nextProps.selectedKey, this.props.user).then(data => {
                    this.props.changeBrand({target: {value: data.brand}});
                    this.props.changeOrigins({target: {value: data.origins}});
                    this.props.changeCDNs({target: {value: data.cdns}});
                    this.props.changeDescription({target: {value: data.description}});
                });
            } else if (nextProps.newClicked && nextProps.newClicked !== this.props.newClicked) {
                this.props.changeBrand({target: {value: ""}});
                this.props.changeDescription({target: {value: ""}});
                this.props.changeOrigins({target: {value: []}});
                this.props.changeCDNs({target: {value: []}});
            }
        }
    }

    render() {
        let { type, selectedKey, action, onClickSave } = this.props;

        if (type.toLowerCase() === "origin") {
            let { originType, originUrl, originPriority, originIsEnabled, originCleanupRegex, changeOriginType, changeOriginUrl,
                changeOriginPriority, changeOriginIsEnabled, changeOriginCleanupRegex } = this.props;
            return (
                <Form>
                    <Header content={`${selectedKey} - ${action} ${type}`}/>
                    <ContingentButton
                        onClick={onClickSave}
                        service={this.props.service}
                        module={this.props.module}
                        scope="all"
                        user={this.props.user}
                        allPermissions={this.props.permissions}
                    >Save</ContingentButton>
                    <Form.Field
                        label="Origin Enabled"
                        control={Checkbox}
                        toggle
                        checked={originIsEnabled}
                        onChange={changeOriginIsEnabled}
                        readOnly={!this.props.userCanEdit}
                        disabled={!this.props.userCanEdit}
                    />
                    <Form.Field
                        label="Origin Type"
                        control={Input}
                        placeholder="Origin Type"
                        value={originType}
                        onChange={changeOriginType}
                        readOnly={!this.props.userCanEdit}
                        disabled={!this.props.userCanEdit}
                    />
                    <Form.Field
                        label="Origin URL"
                        control={Input}
                        placeholder="Origin URL"
                        value={originUrl}
                        onChange={changeOriginUrl}
                        readOnly={!this.props.userCanEdit}
                        disabled={!this.props.userCanEdit}
                    />
                    <Form.Field
                        label="Origin Priority"
                        control={Input}
                        type="number"
                        placeholder="Origin Priority"
                        value={originPriority}
                        onChange={changeOriginPriority}
                        readOnly={!this.props.userCanEdit}
                        disabled={!this.props.userCanEdit}
                    />
                    <Form.Field
                        label="Origin Cleanup Regular Expressions"
                        control={Input}
                        placeholder="Origin Cleanup Regular Expressions"
                        value={originCleanupRegex}
                        onChange={changeOriginCleanupRegex}
                        readOnly={!this.props.userCanEdit}
                        disabled={!this.props.userCanEdit}
                    />
                </Form>
            );
        } else if (type.toLowerCase() === "cdn") {
            let { cdnType, cdnUrl, cdnWeight, cdnPriority, cdnIsSSAI, cdnProtectionType, cdnContentType, cdnIsEnabled,
                cdnCleanupRegex, changeCdnIsEnabled, changeCdnIsSSAI, changeCdnType, changeCdnUrl, changeCdnWeight,
                changeCdnPriority, changeCdnContentType, changeCdnProtectionType, changeCdnCleanupRegex } = this.props;
            return (
                <Form>
                    <Header content={`${selectedKey} - ${action} ${type}`}/>
                    <ContingentButton
                        onClick={onClickSave}
                        service={this.props.service}
                        module={this.props.module}
                        scope="all"
                        user={this.props.user}
                        allPermissions={this.props.permissions}
                    >Save</ContingentButton>
                    <Form.Field
                        label="SSAI Enabled"
                        control={Checkbox}
                        toggle
                        checked={cdnIsSSAI}
                        onChange={changeCdnIsSSAI}
                        readOnly={!this.props.userCanEdit}
                        disabled={!this.props.userCanEdit}
                    />
                    <Form.Field
                        label="CDN Enabled"
                        control={Checkbox}
                        toggle
                        checked={cdnIsEnabled}
                        onChange={changeCdnIsEnabled}
                        readOnly={!this.props.userCanEdit}
                        disabled={!this.props.userCanEdit}
                    />
                    <Form.Field
                        label="CDN Type"
                        control={Input}
                        placeholder="CDN Type"
                        value={cdnType}
                        onChange={changeCdnType}
                        readOnly={!this.props.userCanEdit}
                        disabled={!this.props.userCanEdit}
                    />
                    <Form.Field
                        label="CDN URL"
                        control={Input}
                        placeholder="CDN URL"
                        value={cdnUrl}
                        onChange={changeCdnUrl}
                        readOnly={!this.props.userCanEdit}
                        disabled={!this.props.userCanEdit}
                    />
                    <Form.Field
                        label="CDN Weight"
                        control={Input}
                        type="number"
                        placeholder={0}
                        value={cdnWeight}
                        onChange={changeCdnWeight}
                        readOnly={!this.props.userCanEdit}
                        disabled={!this.props.userCanEdit}
                    />
                    <Form.Field
                        label="CDN Priority"
                        control={Input}
                        type="number"
                        placeholder={0}
                        value={cdnPriority}
                        onChange={changeCdnPriority}
                        readOnly={!this.props.userCanEdit}
                        disabled={!this.props.userCanEdit}
                    />
                    <Form.Field
                        label="CDN Content Type"
                        control={Input}
                        placeholder="CDN Content Type"
                        value={cdnContentType}
                        onChange={changeCdnContentType}
                        readOnly={!this.props.userCanEdit}
                        disabled={!this.props.userCanEdit}
                    />
                    <Form.Field
                        label="CDN Protection Type"
                        control={Input}
                        placeholder="Protection Type"
                        value={cdnProtectionType}
                        onChange={changeCdnProtectionType}
                        readOnly={!this.props.userCanEdit}
                        disabled={!this.props.userCanEdit}
                    />
                    <Form.Field
                        label="CDN Cleanup Regular Expressions"
                        control={Input}
                        placeholder="CDN Cleanup Regular Expressions"
                        value={cdnCleanupRegex}
                        onChange={changeCdnCleanupRegex}
                        readOnly={!this.props.userCanEdit}
                        disabled={!this.props.userCanEdit}
                    />
                </Form>
            );
        } else {
            let { brand, description } = this.props;
            return (
                <Form>
                    <Header content={selectedKey}/>
                    <ContingentButton
                        onClick={onClickSave}
                        service={this.props.service}
                        module={this.props.module}
                        scope="all"
                        user={this.props.user}
                        allPermissions={this.props.permissions}
                    >Save</ContingentButton>
                    <br/>
                    <br/>
                    <Form.Field
                        label="Brand"
                        control={Input}
                        placeholder="Brand"
                        onChange={this.props.changeBrand}
                        value={brand}
                        readOnly={!this.props.userCanEdit}
                        disabled={!this.props.userCanEdit}
                    />
                    <Form.Field
                        label="Description"
                        control={Input}
                        placeholder="Description"
                        onChange={this.props.changeDescription}
                        value={description}
                        readOnly={!this.props.userCanEdit}
                        disabled={!this.props.userCanEdit}
                    />
                </Form>
            );
        }
    }
}

export default OriginCDNForm;
