import React, {useState, useEffect} from "react";
import {Button, Form, Grid, Input, Icon, List, Card, Message, Segment} from "semantic-ui-react";

export default function RelaysJobSelector(props) {
    const [filteredJobs, setFilteredJobs] = useState([]);
    const [filter, setFilter] = useState("");

    useEffect(function initJobs() {
        props.refreshJobs();
    }, []);

    useEffect(function updateFilteredJobs() {
        if (filter && props.jobs.length > 0) {
            const updatedFilteredJobs = [];
            for (const job of props.jobs) {
                console.log(job);
                if (job.hasOwnProperty("_id") && job._id.toString().toLowerCase().includes(filter.toLowerCase())) {
                    updatedFilteredJobs.push(job);
                }
            }

            setFilteredJobs(updatedFilteredJobs);
        } else {
            setFilteredJobs(props.jobs);
        }

    }, [filter, JSON.stringify(props.jobs)]);

    useEffect(() => {
        console.log("selected job id: ", props.selectedJobId);
    }, [JSON.stringify(props.selectedJobId)]);

    const formatProperty = (key, value) => {
        return <span><b>{key}</b>: {value}</span>
    };

    return (
        <Grid>
            <Grid.Column width={16}>
                <Grid.Row>
                    <Form>
                        <Form.Group>
                            <Form.Field>
                                <Button positive icon onClick={() => props.setNewClicked(true)}><Icon name="plus"/>Create New Job</Button>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <Form.Field
                                control={Input}
                                label="Job Name"
                                value={filter}
                                placeholder="Filter Jobs by ID"
                                onChange={(event, {value}) => setFilter(value)}
                            />
                            <Form.Field>
                                <label>&nbsp;</label>
                                <Button onClick={() => props.refreshJobs()} icon primary size="medium">&nbsp;<Icon name="refresh"/></Button>
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </Grid.Row>
                <Grid.Row>
                    {
                        props.loadingJobs ?
                            <Message icon color="yellow">
                                <Icon loading name="spinner" />
                                <Message.Content>Loading Jobs...</Message.Content>
                            </Message> :
                        props.loadingErrorMessage.length > 0 ?
                            <Message>{props.loadingErrorMessage}</Message> : ""
                    }
                    <List style={{maxHeight: "67vh", overflowY: "auto"}}>
                        {
                            filteredJobs.map(job =>
                                <List.Item
                                    key={job._id}
                                    onClick={() => props.setSelectedJobId(job._id)}
                                >
                                    <List.Content>
                                        <Segment secondary={props.selectedJobId === job._id} inverted={props.selectedJobId === job._id} color={props.selectedJobId === job._id ? "blue" : ""}>
                                                <h5>{job._id}</h5>
                                                {job.source ? formatProperty("Source", job.source) : ""}
                                                <br />
                                                {job.type ? formatProperty("Type", job.type) : ""}
                                        </Segment>
                                    </List.Content>
                                </List.Item>
                            )
                        }
                    </List>
                </Grid.Row>
            </Grid.Column>
        </Grid>
    );
};
