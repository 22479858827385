import React, {useState, useEffect} from "react";
import {Grid} from "semantic-ui-react";
import RelaysTemplateSelector from "./RelaysTemplateSelector";
import RelaysTemplateDetailView from "./RelaysTemplateDetailView";

export default function RelaysTemplateTab(props) {
    const [templates, setTemplates] = useState({});
    const [selectedTemplateInitialState, setSelectedTemplateInitialState] = useState({});
    const [selectedTemplate, setSelectedTemplate] = useState({});
    const [selectedTemplateId, setSelectedTemplateId] = useState("");
    const [newClicked, setNewClicked] = useState(false);
    const [duplicateClicked, setDuplicateClicked] = useState(false);
    const [loadingErrorMessage, setLoadingErrorMessage] = useState("");
    const [saveTemplateSuccessMessage, setSaveTemplateSuccessMessge] = useState("");
    const [saveTemplateErrorMessage, setSaveTemplateErrorMessage] = useState("");
    const [loadingTemplates, setLoadingTemplates] = useState(false);
    const [savingTemplate, setSavingTemplate] = useState(false);

    useEffect(function init() {
        props.RelaysDataProvider.init({baseURL: process.env.RELAYS_URL});

        refreshTemplates();
    }, []);

    useEffect(function onClickCreateNew() {
        if (newClicked) {
            const defaultTemplate = {
                source: ""
            }
            setSaveTemplateErrorMessage("");
            setSaveTemplateSuccessMessge("");
            setSelectedTemplateId("");
            setSelectedTemplate(Object.assign({}, defaultTemplate));
            setSelectedTemplateInitialState(Object.assign({}, defaultTemplate));
        }
    }, [newClicked]);

    useEffect(function newTemplateSelected() {
        setSaveTemplateSuccessMessge("");
        setSaveTemplateErrorMessage("");
        setSavingTemplate(false);
        if (!newClicked) {
            if (selectedTemplateId && templates.hasOwnProperty(selectedTemplateId)) {
                setSelectedTemplateInitialState(Object.assign({}, templates[selectedTemplateId]));
                setSelectedTemplate(Object.assign({}, templates[selectedTemplateId]));
            } else {
                setSelectedTemplateInitialState({});
                setSelectedTemplate({});
            }
        }
    }, [selectedTemplateId]);

    const refreshTemplates = () => {
        setLoadingTemplates(true);
        setLoadingErrorMessage("");
        props.RelaysDataProvider.getTemplates().then(response => {
            if (response.hasOwnProperty("error")) {
                setLoadingErrorMessage("There has been an error retrieving templates from Relays.");
                console.error("RelaysTemplateTab.refreshTemplates error: ", error);
            } else if (response.hasOwnProperty("templates")) {
                console.log("RelaysTemplateTab.refreshTemplates got templates: ", response.templates);
                const decodedTemplates = [];
                for (const id of Object.keys(response.templates)) {
                    console.log(response.templates[id])
                    const originalTemplate = response.templates[id];
                    const template = {};
                    const decodedTemplate = JSON.parse(atob(originalTemplate.fields));

                    for (const templateField of decodedTemplate) {
                        template[templateField.key] = {
                            key: templateField.key,
                            value: templateField.value,
                            datatype: templateField.type,
                            readonly: templateField.readonly || false
                        }
                    }

                    decodedTemplates.push(template);
                }
                setTemplates(decodedTemplates);
            } else {
                setTemplates({});
            }
        }).catch(error => {
            setTemplates({});
            setLoadingErrorMessage("There has been an error retrieving templates from Relays.");
            console.error("RelaysTemplateTab.refreshTemplates error: ", error);
        }).finally(() => {
            setLoadingTemplates(false);
        });
    };

    const onClickSave = template => {
        setSaveTemplateSuccessMessge("");
        setSaveTemplateErrorMessage("");
        console.log(template);
        let source, type;
        let fields = [];
        for (const field of template) {
            if (field.key === "source") {
                source = field.value;
            } else if (field.key === "type") {
                type = field.value;
            }
            fields.push(field);
        }

        if (!source) {
            setSaveTemplateErrorMessage("Relays templates must have a \"source\" key.");
            return;
        }

        setSavingTemplate(true);
        props.RelaysDataProvider.setTemplate(source, type, fields).then(response => {
            console.log("RelaysTemplateTab.onClickSave response: ", response);
            if (response.error) {
                console.error(response.error);
                setSaveTemplateErrorMessage("There has been an error saving this template.");
                return;
            }
            refreshTemplates();
            setSaveTemplateSuccessMessge(`The template with source ${source.value} ${type ? `and type ${type.value}` : ""} has been saved.`);
        }).catch(error => {
            console.error(error);
            setSaveTemplateErrorMessage("There has been an error saving this template.");
        }).finally(() => {
            setSavingTemplate(false);
        });
    };

    return (
        <Grid className="masterContainer">
            <Grid.Column width={3}>
                <RelaysTemplateSelector
                    setNewClicked={setNewClicked}
                    newClicked={newClicked}
                    selectedTemplateId={selectedTemplateId}
                    setSelectedTemplateId={setSelectedTemplateId}
                    templates={templates}
                    refreshTemplates={refreshTemplates}
                    loadingTemplates={loadingTemplates}
                    loadingErrorMessage={loadingErrorMessage}
                />
            </Grid.Column>
            <Grid.Column width={13}>
                <RelaysTemplateDetailView
                    selectedTemplate={selectedTemplate}
                    newClicked={newClicked}
                    RelaysDataProvider={props.RelaysDataProvider}
                    duplicateClicked={duplicateClicked}
                    setDuplicateClicked={setDuplicateClicked}
                    saveTemplateSuccessMessage={saveTemplateSuccessMessage}
                    saveTemplateErrorMessage={saveTemplateErrorMessage}
                    onClickSave={onClickSave}
                    savingTemplate={savingTemplate}
                />
            </Grid.Column>
        </Grid>
    );
};
