import React, {useEffect} from "react";
import {Grid, Tab} from "semantic-ui-react";
import RelaysDataProvider from "../../../Services/RelaysDataProvider";
import RelaysJobsTab from "./RelaysJobsTab";
import RelaysMetadataTab from "./RelaysMetadataTab";
import RelaysReprocessTab from "./RelaysReprocessTab";
import SecureTabPane from "../../SecureTabPane";
import RelaysTemplateTab from "./RelaysTemplateTab";

export default function RelaysAdminMasterDetailView(props) {
    useEffect(function init() {
        RelaysDataProvider.init({baseURL: process.env.RELAYS_URL});
    }, []);

    return (
        <Grid className="masterDetailContainer">
            <Grid.Column width={16} className="masterContainer">
                <Tab
                    panes={[
                        {
                            menuItem: {
                                key: "relays-jobs-tab",
                                content: <label>Relays Jobs</label>
                            },
                            render: () =>
                                <RelaysJobsTab
                                    RelaysDataProvider={RelaysDataProvider}
                                />
                        },
                        {
                            menuItem: {
                                key: "relays-templates-tab",
                                content: <label>Relays Templates</label>
                            },
                            render: () =>
                                <RelaysTemplateTab
                                    RelaysDataProvider={RelaysDataProvider}
                                />
                        },
                        {
                            menuItem: {
                                key: "relays-metadata-tab",
                                content: <label>Relays Metadata</label>
                            },
                            render: () =>
                                <RelaysMetadataTab
                                    RelaysDataProvider={RelaysDataProvider}
                                />
                        }, {
                            menuItem: {
                                key: "relays-reprocess-odt-tab",
                                content: <label>Reprocess ODT</label>
                            },
                            render: () =>
                                <SecureTabPane
                                    user={props.user}
                                    authenticated={props.authenticated}
                                    checkIfAuthorized={props.checkIfAuthorized}
                                    permissionsRequired={props.permissionsRequired}
                                    component={<RelaysReprocessTab toast={props.toast} RelaysDataProvider={RelaysDataProvider} />}
                                    service={props.service}
                                    module={props.module}
                                    permissions={props.permissions}
                                    userPermissions={props.userPermissions}
                                />
                        }
                    ]}
                />
            </Grid.Column>
        </Grid>
    );
};
