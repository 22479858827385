import React, {useState, useEffect} from "react";
import {Grid} from "semantic-ui-react";
import RelaysJobSelector from "./RelaysJobSelector";
import RelaysJobDetailView from "./RelaysJobDetailView";
import RelaysDataProvider from "../../../Services/RelaysDataProvider";
import RelaysTemplateSelectModal from "./RelaysTemplateSelectModal";

RelaysDataProvider.init({baseURL: process.env.RELAYS_URL});

export default function RelaysJobsTab(props) {
    const [selectedJobId, setSelectedJobId] = useState("");
    const [selectedJob, setSelectedJob] = useState({});
    const [newClicked, setNewClicked] = useState(false);
    const [duplicateClicked, setDuplicateClicked] = useState(false);
    const [jobs, setJobs] = useState([]);
    const [idList, setIdList] = useState([]);
    const [templates, setTemplates] = useState({});
    const [selectedTemplate, setSelectedTemplate] = useState("");
    const [loadingJobs, setLoadingJobs] = useState(false);
    const [loadingErrorMessage, setLoadingErrorMessage] = useState("");
    const [savingJob, setSavingJob] = useState(false);
    const [saveJobSuccessMessage, setSaveJobSuccessMessage] = useState("");
    const [saveJobErrorMessage, setSaveJobErrorMessage] = useState("");

    useEffect(function loadTemplates() {
        props.RelaysDataProvider.getTemplates().then(response => {
            const formattedTemplates = {};
            for (const key of Object.keys(response.templates)) {
                const template = response.templates[key];
                formattedTemplates[key] = JSON.parse(atob(template.fields));
            }
            setTemplates(formattedTemplates);
        }).catch(error => {
            console.error("RelaysJobsTab.loadTemplates error: ", error);
        });
    }, []);

    useEffect(function updateIdList() {
        const updatedIdList = [];
        if (Object.keys(jobs).length > 0) {
            for (const job of jobs) {
                updatedIdList.push(job._id);
            }
        }
        setIdList(updatedIdList);
    }, [JSON.stringify(jobs)]);

    useEffect(function onClickNew() {
        if (newClicked && !duplicateClicked) {
            setSaveJobSuccessMessage("");
            setSaveJobErrorMessage("");
            setSelectedJob({
                "_id": ""
            });
        } else {
            setSelectedTemplate("");
        }
    }, [newClicked]);

    useEffect(function loadFromTemplate() {
        if (newClicked && selectedTemplate && templates.hasOwnProperty(selectedTemplate)) {
            const template = templates[selectedTemplate];
            const updatedJob = Object.assign({}, selectedJob);

            for (const field of template) {
                updatedJob[field.key] = {
                    value: field.value,
                    type: field.type,
                    isLocked: field.readonly
                };
            }

            setSelectedJob(updatedJob);
        }
    }, [selectedTemplate, JSON.stringify(templates), newClicked]);

    useEffect(function onSelectNewJob() {
        if (selectedJobId) {
            let updatedSelectedJob = {};
            for (const job of jobs) {
                if (job._id === selectedJobId) {
                    updatedSelectedJob = Object.assign({}, job);
                    break;
                }
            }

            const source = updatedSelectedJob.source;
            const type = updatedSelectedJob.type;
            let template = {};

            for (const key of Object.keys(templates)) {
                let sourceFound = false;
                let typeFound = false;
                let templateHasType = false;
                for (const property of templates[key]) {
                    if (property.key === "type") {
                        templateHasType = true;
                    }
                    sourceFound ||= property.key === "source" && property.value === source;
                    typeFound ||= property.key === "type" && property.value === type;
                    if (sourceFound && typeFound) {
                        break;
                    }
                }
                if (sourceFound && (typeFound || !templateHasType)) {
                    for (const property of templates[key]) {
                        template[property.key] = {
                            value: property.value,
                            type: property.type,
                            readonly: property.readonly
                        };
                    }
                    break;
                }
            }

            for (const key of Object.keys(updatedSelectedJob)) {
                updatedSelectedJob[key] = {
                    value: updatedSelectedJob[key],
                    type: template.hasOwnProperty(key) ? template[key].type : typeof updatedSelectedJob[key],
                    lockType: template.hasOwnProperty(key),
                    isLocked: template.hasOwnProperty(key) && template[key].readonly,
                    highlight: !template.hasOwnProperty(key)
                }
            }

            for (const property of Object.keys(template)) {
                if (!updatedSelectedJob.hasOwnProperty(property)) {
                    updatedSelectedJob[property] = {
                        value: template[property].value,
                        type: template[property].type,
                        lockType: true,
                        isLocked: template[property].readonly && updatedSelectedJob.hasOwnProperty(property),
                        highlight: false
                    };
                }
            }

            setSelectedJob(updatedSelectedJob);
            setNewClicked(false);
            setSaveJobSuccessMessage("");
            setSaveJobErrorMessage("");
        } else {
            setSelectedJob({});
        }
    }, [selectedJobId]);

    const duplicateSelectedJob = () => {
        setDuplicateClicked(true);
        setNewClicked(true);
        setSaveJobSuccessMessage("");
        setSaveJobErrorMessage("");
        const updatedSelectedJob = Object.assign({}, selectedJob, {_id: ""});
        setSelectedJob(updatedSelectedJob);
    };

    const onClickSave = properties => {
        const job = {};
        for (const property of properties) {
            if (property.type === "number" && Number.isNaN(property.value)) {
                setSaveJobErrorMessage(`The value for ${property.key} is supposed to be a number, but its value is ${property.value}. You must either change the type of ${property.key} or set its value to a real number.`);
                return;
            }
            if ([undefined, ""].includes(property.key)) {
                setSaveJobErrorMessage("Jobs will not be saved if they include empty keys.");
                return;
            }
            if ([undefined, ""].includes(property.value)) {
                setSaveJobErrorMessage(`The key ${property.key} has an empty value. Please input a value in order to save this job.`);
                return;
            }

            job[property.key] = property.value;
        }

        if (!job._id) {
            setSaveJobErrorMessage("You must provide an ID for the new job.");
            return;
        }

        if (!job.source) {
            setSaveJobErrorMessage("You must specify the source for this job.");
        }

        setSaveJobErrorMessage("");
        setSaveJobSuccessMessage("");
        setSavingJob(true);
        RelaysDataProvider.saveJob(job).then(response => {
            setNewClicked(false);
            setDuplicateClicked(false);
            setSaveJobSuccessMessage(`The job with id ${job._id} has been saved. It may take a moment for the updates to show.`);
        }).catch(error => {
            setSaveJobErrorMessage(`There was an error saving the job with id ${selectedJob._id}`);
            console.error("RelaysJobsTab.saveClicked: there was an error saving the job:", selectedJob, error);
        }).finally(() => {
            setSavingJob(false);
            refreshJobs();
        });
    };

    const refreshJobs = () => {
        setLoadingJobs(true);
        setLoadingErrorMessage("");
        props.RelaysDataProvider.getJobs().then(jobs => {
            if (jobs.error) {
                setLoadingErrorMessage("There was an error loading the list of the jobs in Relays.");
                setJobs([]);
                console.error("(RelaysJobSelector.initJobs) error: ", jobs.error);
            } else {
                setJobs(jobs);
            }
        }).catch(error => {
            setLoadingErrorMessage("There was an error loading the list of the jobs in Relays.");
            setJobs([]);
            console.error("(RelaysJobSelector.initJobs) error: ", error);
        }).finally(() => {
            setLoadingJobs(false);
        });
    };

    return (
        <Grid className="masterContainer">
            <RelaysTemplateSelectModal
                templates={templates}
                newClicked={newClicked}
                setNewClicked={setNewClicked}
                setSelectedTemplate={setSelectedTemplate}
            />
            <Grid.Column width={3}>
                <RelaysJobSelector
                    setNewClicked={setNewClicked}
                    newClicked={newClicked}
                    selectedJobId={selectedJobId}
                    setSelectedJobId={setSelectedJobId}
                    jobs={jobs}
                    refreshJobs={refreshJobs}
                    loadingJobs={loadingJobs}
                    loadingErrorMessage={loadingErrorMessage}
                />
            </Grid.Column>
            <Grid.Column width={13}>
                <RelaysJobDetailView
                    selectedJob={selectedJob}
                    newClicked={newClicked}
                    RelaysDataProvider={props.RelaysDataProvider}
                    duplicateClicked={duplicateClicked}
                    setDuplicateClicked={setDuplicateClicked}
                    duplicateSelectedJob={duplicateSelectedJob}
                    saveJobSuccessMessage={saveJobSuccessMessage}
                    saveJobErrorMessage={saveJobErrorMessage}
                    onClickSave={onClickSave}
                    savingJob={savingJob}
                    idList={idList}
                />
            </Grid.Column>
        </Grid>
    );
};
