import React, {useState, useEffect} from "react";
import {Button, Container, Grid, Icon, Message, Table} from "semantic-ui-react";
import MetadataTableRow from "../../MetadataTable/MetadataTableRow";

const DATA_TYPES = [
    {key: "blank", text: "", value: ""},
    {key: "boolean", text: "Boolean", value: "boolean"},
    {key: "array", text: "Array", value: "array"},
    {key: "number", text: "Number", value: "number"},
    {key: "object", text: "Object", value: "object"},
    {key: "string", text: "String", value: "string"},
];

export default function RelaysTemplateDetailView(props) {
    const [templateProperties, setTemplateProperties] = useState([]);

    useEffect(function formatTemplateProperties() {
        const updatedTemplateProperties = [];
        if (Object.keys(props.selectedTemplate).length > 0) {
            for (const key of Object.keys(props.selectedTemplate)) {
                let value = props.selectedTemplate[key].value;
                if (value === undefined) {
                    value = "";
                }
                if (props.selectedTemplate[key].datatype === "array" && !Array.isArray(value)) {
                    value = [value];
                }
                updatedTemplateProperties.push({
                    key: key,
                    type: props.selectedTemplate[key].datatype || "string",
                    value,
                    readonly: props.selectedTemplate[key].readonly === true
                });
            }
        }
        setTemplateProperties(updatedTemplateProperties);
    }, [JSON.stringify(props.selectedTemplate), props.newClicked]);

    const updatePropertyKey = (index, key) => {
        if (index < templateProperties.length) {
            console.log(`RelaysJobDetailView.updatePropertyKey setting key to ${key} for index ${index}`);
            setTemplateProperties(templateProperties.slice().map((property, i) => {
                return i !== index ? property : {
                    key,
                    value: property.value,
                    type: property.type,
                    readonly: property.readonly
                };
            }));
        } else {
            console.log(`RelaysJobDetailView.updatePropertyKey attempted to update property with index ${index} when there are only ${templateProperties.length} properties`);
        }
    };

    const updatePropertyReadOnly = (index, readonly) => {
        if (index < templateProperties.length) {
            setTemplateProperties(templateProperties.slice().map((property, i) => {
                return i !== index ? property : {
                    key: property.key,
                    value: property.value,
                    type: property.type,
                    readonly: readonly
                }
            }));
        }
    };

    const updatePropertyValue = (index, value, propertyType) => {
        if (index < templateProperties.length) {
            const updatedTemplateProperties = [];
            for (let i = 0; i < templateProperties.length; i++) {
                const readonly = templateProperties[i].readonly;
                if (i === index) {
                    const currentType =
                        Array.isArray(templateProperties[i].value) ? "array" :
                            [true, false].includes(templateProperties[i].value) ? "boolean" :
                                typeof templateProperties[i] === "string" ? "string" :
                                    typeof templateProperties[i];
                    if (currentType === propertyType) {
                        console.log(`RelaysJobDetailView.updatePropertyValue setting property to ${JSON.stringify(value)} for key ${templateProperties[index].key}`);
                        updatedTemplateProperties.push({
                            key: templateProperties[i].key,
                            value,
                            type: propertyType,
                            readonly
                        });
                    } else {
                        switch (propertyType) {
                            case "array": value = [value]; break;
                            case "object": value = {"": value}; break;
                            case "number": value = parseFloat(value); break;
                            case "boolean": value = value.toString() !== false; break;
                            case "string": value = value.toString(); break;
                            default: console.log("unexpected property type: ", propertyType);
                        }

                        console.log(`RelaysJobDetailView.updatePropertyValue updating property type to ${propertyType} from ${currentType}, setting property to ${JSON.stringify(value)} for key ${templateProperties[index].key}`);

                        updatedTemplateProperties.push({
                            key: templateProperties[i].key,
                            value,
                            type: propertyType,
                            readonly
                        });
                    }
                } else {
                    updatedTemplateProperties.push(templateProperties[i]);
                }
            }
            setTemplateProperties(updatedTemplateProperties);
        } else {
            console.log(`RelaysJobDetailView.updatePropertyValue attempted to update property with index ${index} when there are only ${templateProperties.length} properties`);
        }
    };

    const addProperty = () => {
        const updatedTemplateProperties = templateProperties.slice();
        updatedTemplateProperties.push({key: "", value: "", type: "string", readonly: false});
        setTemplateProperties(updatedTemplateProperties);
    };

    const removeProperty = index => {
        const updatedTemplateProperties = templateProperties.slice();
        updatedTemplateProperties.splice(index, 1);
        console.log("updated template properties: ", updatedTemplateProperties)
        setTemplateProperties(updatedTemplateProperties);
    };

    return (
        <Grid>
            <Grid.Row style={{height: "83vh", overflowY: "auto"}}>
                <Grid.Column width={16}>
                    {
                        templateProperties.length < 1 && !props.newClicked ?
                            <Message icon color="yellow">
                                <Icon name="exclamation" />
                                <Message.Content>
                                    Please select a template to see and modify its properties.
                                </Message.Content>
                            </Message> :
                            <Container fluid>
                                <Button primary onClick={() => props.onClickSave(templateProperties)}><Icon name="save outline" />Save</Button>
                                {
                                    props.savingTemplate ?
                                        <Message icon color="blue">
                                            <Icon loading name="spinner" />
                                            <Message.Content>
                                                Saving template...
                                            </Message.Content>
                                        </Message> :
                                        props.saveTemplateSuccessMessage.length > 0 ?
                                            <Message icon color="green">
                                                <Icon name="check circle" />
                                                <Message.Content>
                                                    The template with source {props.selectedTemplate.source.value} {props.selectedTemplate.type ? `and type ${props.selectedTemplate.type.value}` : ""} has been saved.
                                                </Message.Content>
                                            </Message> :
                                            props.saveTemplateErrorMessage.length > 0 ?
                                                <Message icon color="red">
                                                    <Icon name="exclamation circle" />
                                                    <Message.Content>{props.saveTemplateErrorMessage}</Message.Content>
                                                </Message> : ""
                                }
                                <Table verticalAlign="top" compact>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell width={1} />
                                            <Table.HeaderCell width={3}>Key</Table.HeaderCell>
                                            <Table.HeaderCell width={2}>Type</Table.HeaderCell>
                                            <Table.HeaderCell width={9}>Value</Table.HeaderCell>
                                            <Table.HeaderCell width={1}>Read Only</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {
                                            templateProperties.map((property, index) =>
                                                <MetadataTableRow
                                                    key={`property-key-${index}`}
                                                    index={index}
                                                    property={property}
                                                    updatePropertyKey={updatePropertyKey}
                                                    updatePropertyValue={updatePropertyValue}
                                                    removeProperty={removeProperty}
                                                    updatePropertyReadOnly={updatePropertyReadOnly}
                                                    DATA_TYPES={DATA_TYPES}
                                                    newClicked={props.newClicked}
                                                    duplicateClicked={props.duplicateClicked}
                                                />
                                            )
                                        }
                                    </Table.Body>
                                    <Table.Footer fullWidth>
                                        <Table.Row>
                                            <Table.Cell colSpan="5">
                                                <Button fluid onClick={() => addProperty()}>Add Item</Button>
                                            </Table.Cell>
                                        </Table.Row>
                                    </Table.Footer>
                                </Table>
                            </Container>
                    }
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};
