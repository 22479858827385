import React, { Component } from 'react';
import {Button, Container, Grid } from "semantic-ui-react";
import ReactTable from 'react-table-v6';
import OriginCDNForm from "./OriginCDNForm";
import Provider from "../../../Services/OriginCDNMapperDataProvider";
import URLTableGrid from "./URLTableGrid";
import PropTypes from "prop-types";
import ContingentButton from "../../ContingentButton";

Provider.init({baseURL: process.env.ORIGIN_CDN_MAPPER_URL});

export default class OriginCDNMapperMasterDetailView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: false,
            selectedKey: "",
            newClicked: false,
            brand: "",
            origins: [],
            cdns: [],
            cdnContentType: "",
            expanded: {},
            selectedOrigin: {},
            selectedCDN: {},
            action: "",
            type: "",
            cdnIndex: "",
            cdnType: "",
            cdnUrl: "",
            cdnWeight: 0,
            cdnPriority: 0,
            cdnProtectionType: "",
            cdnIsSSAI: false,
            cdnIsEnabled: true,
            cdnCleanupRegex: "",
            originIndex: "",
            originType: "",
            originUrl: "",
            originPriority: 0,
            originIsEnabled: true,
            originCleanupRegex: "",
            description: "",
            userCanEdit: false,
        };

        this.loadData = this.loadData.bind(this);
        this.changeBrand = this.changeBrand.bind(this);
        this.changeOriginUrl = this.changeOriginUrl.bind(this);
        this.changeOriginIsEnabled = this.changeOriginIsEnabled.bind(this);
        this.changeCdnUrl = this.changeCdnUrl.bind(this);
        this.changeCdnContentType = this.changeCdnContentType.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.changeOriginType = this.changeOriginType.bind(this);
        this.changeOriginPriority = this.changeOriginPriority.bind(this);
        this.changeCdnType = this.changeCdnType.bind(this);
        this.changeCdnIsEnabled = this.changeCdnIsEnabled.bind(this);
        this.onClickNew = this.onClickNew.bind(this);
        this.changeCdnIsSSAI = this.changeCdnIsSSAI.bind(this);
        this.addCDNClicked = this.addCDNClicked.bind(this);
        this.deleteCDNClicked = this.deleteCDNClicked.bind(this);
        this.changeSelectedCDN = this.changeSelectedCDN.bind(this);
        this.addOriginClicked = this.addOriginClicked.bind(this);
        this.deleteOriginClicked = this.deleteOriginClicked.bind(this);
        this.changeSelectedOrigin = this.changeSelectedOrigin.bind(this);
        this.changeAction = this.changeAction.bind(this);
        this.changeDescription = this.changeDescription.bind(this);

        this.addOrigin = this.addOrigin.bind(this);
        this.deleteOrigin = this.deleteOrigin.bind(this);
        this.addCDN = this.addCDN.bind(this);
        this.deleteCDN = this.deleteCDN.bind(this);
        this.editCDN = this.editCDN.bind(this);
        this.changeCdnProtectionType = this.changeCdnProtectionType.bind(this);
        this.changeCdnCleanupRegex = this.changeCdnCleanupRegex.bind(this);
        this.pushChanges = this.pushChanges.bind(this);
        this.writeChanges = this.writeChanges.bind(this);
        this.save = this.save.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(this.props.permissions) !== JSON.stringify(prevProps.permissions)) {
            this.updatePermissions();
        }
    }

    componentDidMount() {
        this.loadData();
        this.updatePermissions();
    }

    updatePermissions = () => {
        let updatedUserCanEdit = false;
        for (const permission of this.props.permissions) {
            updatedUserCanEdit ||= (permission.service === "all" && permission.module === "all" && permission.scope === "all" && permission.role === "aspen-power") || (permission.module === this.props.module && permission.service === this.props.service && permission.role === "editor");
            if (updatedUserCanEdit) {
                console.log("AdConfigurationMasterDetailView.updatePermissions updatedUserIsEditor:", updatedUserCanEdit);
                break;
            }
        }
        this.setState({userCanEdit: updatedUserCanEdit});
    }

    loadData() {
        this.setState({loading: true}, () => {
            Provider.getAll(this.props.user).then(data => {
                let updatedData = [];
                if (data.error) {
                    this.props.toast("Error Retrieving Data", data.error.message);
                } else {
                    updatedData = data.slice();
                }
                this.setState({
                    data: updatedData
                });
            }).catch(error => {
                console.error(error);
                this.props.toast("Error", error.message);
                this.setState({data: null});
            }).finally(() => this.setState({loading: false}));
        });
    }

    onClickNew() {
        if (this.state.userCanEdit) {
            this.setState({newClicked: true, brand: "", origins: [], cdns: [], description: "", selectedKey: "", action: "Add", type: "Mapping"});
        }
    }

    save() {
        new Promise((resolve) => {
            if (this.state.type.toLowerCase() !== "mapping") {
                this.pushChanges();
            }
            resolve();
        }).then(() => {
            this.writeChanges();
        }).then(() => {
            this.loadData();
        });
    }

    pushChanges() {
        switch (this.state.action) {
            case "Add":
                if (this.state.type.toLowerCase() === "cdn") {
                    this.addCDN();
                } else if (this.state.type.toLowerCase() === "origin") {
                    this.addOrigin();
                }
                break;
            case "Delete":
                if (this.state.type.toLowerCase() === "cdn") {
                    this.deleteCDN();
                } else if (this.state.type.toLowerCase() === "origin") {
                    this.deleteOrigin();
                }
                break;
            case "Modify":
                if (this.state.type.toLowerCase() === "cdn") {
                    this.editCDN();
                } else if (this.state.type.toLowerCase() === "origin") {
                    this.editOrigin();
                }
                break;
            default:
                throw new Error("Invalid Action");
        }
    }

    onClickSave() {
        this.save();
    }

    writeChanges() {
        if (this.state.userCanEdit) {
            let originCDNMap = {
                mapping_id: this.state.selectedKey,
                brand: this.state.brand,
                origins: this.state.origins,
                cdns: this.state.cdns,
                description: this.state.description
            };

            if (this.state.newClicked) {
                Provider.new(originCDNMap, this.props.user).then(response => {
                    if (response.error) throw new Error(response.error);
                    this.setState({newClicked: false});
                    this.loadData();
                }).catch(error => {
                    this.props.toast("Could Not Save", error.message);
                    console.error(error);
                });
            } else {
                Provider.update(originCDNMap, this.props.user).then(response => {
                    if (response.error) throw new Error(response.error);
                    this.loadData();
                }).catch(error => {
                    this.props.toast("Could Not Save", error.message);
                    console.error(error);
                });
            }
        }
    }

    changeBrand = event => this.setState({brand: event.target.value});
    changeOriginType = event => this.setState({originType: event.target.value});
    changeOriginUrl = event => this.setState({originUrl: event.target.value});
    changeOriginPriority = event => this.setState({originPriority: event.target.value});
    changeOriginIsEnabled = (event, data) => this.setState({originIsEnabled: data.checked});
    changeOriginCleanupRegex = event => this.setState({originCleanupRegex: event.target.value});
    changeCdnType = event => this.setState({cdnType: event.target.value});
    changeCdnUrl = event => this.setState({cdnUrl: event.target.value});
    changeCdnWeight = event => this.setState({cdnWeight: event.target.value});
    changeCdnPriority = event => this.setState({cdnPriority: event.target.value});
    changeCdnProtectionType = event => this.setState({cdnProtectionType: event.target.value});
    changeCdnContentType = event => this.setState({cdnContentType: event.target.value});
    changeCdnIsSSAI = (event, data) => this.setState({cdnIsSSAI: data.checked});
    changeCdnIsEnabled = (event, data) => this.setState({cdnIsEnabled: data.checked});
    changeCdnCleanupRegex = (event) => this.setState({cdnCleanupRegex: event.target.value});
    changeOrigins = event => this.setState({origins: event.target.value});
    changeCDNs = event => this.setState({cdns: event.target.value});
    changeDescription = event => this.setState({description: event.target.value});

    addOriginClicked = () => this.setState({action: "Add", type: "Origin", originType: "", originUrl: "", originPriority: 0});
    deleteOriginClicked = () => this.setState({action: "Delete", type: "Origin"}, () => this.save());
    addCDNClicked = () => this.setState({action: "Add", type: "CDN", cdnType: "", cdnUrl: "", cdnWeight: 0, cdnPriority: 0, cdnProtectionType: "", cdnContentType: "" });
    deleteCDNClicked = () => this.setState({action: "Delete", type: "CDN"}, () => this.save());
    changeOriginIndex = originIndex => this.setState({originIndex});
    changeCdnIndex = cdnIndex => this.setState({cdnIndex});

    addOrigin = () => {
        if (this.state.originType && this.state.originUrl) {
            let origins = this.state.origins;
            let newOrigin = {
                originType: this.state.originType,
                originUrl: this.state.originUrl,
                originPriority: parseInt(this.state.originPriority),
                originIsEnabled: this.state.originIsEnabled,
                originCleanupRegex: this.state.originCleanupRegex
            };
            if (origins.filter(origin => origin.originType === newOrigin.originType && origin.originUrl === newOrigin.originUrl).length === 0) {
                origins.push(newOrigin);
                this.setState({origins});
            } else {
                this.props.toast("Error", "The Origin already exists within this mapping.");
            }
        }
    };

    deleteOrigin = () => this.setState({origins: this.state.origins.filter((origin, index) => index !== this.state.originIndex)});

    editOrigin = () => {
        if (this.state.originType && this.state.originUrl) {
            let origins = this.state.origins;
            origins[this.state.originIndex] = {
                originType: this.state.originType,
                originUrl: this.state.originUrl,
                originPriority: parseInt(this.state.originPriority),
                originIsEnabled: this.state.originIsEnabled,
                originCleanupRegex: this.state.originCleanupRegex
            };
            this.setState({origins});
        }
    };

    addCDN = () => {
        if (["", null, "null", undefined, "none"].includes(this.state.cdnProtectionType)) {
            this.setState({cdnProtectionType: null});
        }

        if (this.state.cdnType && this.state.cdnUrl && this.state.cdnContentType) {
            let cdns = this.state.cdns;
            let newCDN = {
                cdnType: this.state.cdnType,
                cdnUrl: this.state.cdnUrl,
                cdnWeight: parseInt(this.state.cdnWeight),
                cdnPriority: parseInt(this.state.cdnPriority),
                cdnIsSSAI: this.state.cdnIsSSAI,
                cdnContentType: this.state.cdnContentType,
                cdnProtectionType: this.state.cdnProtectionType,
                cdnIsEnabled: this.state.cdnIsEnabled,
                cdnCleanupRegex: this.state.cdnCleanupRegex
            };
            if (cdns.filter(cdn => cdn.cdnType === newCDN.cdnType && cdn.cdnUrl === newCDN.cdnUrl &&
                cdn.cdnIsSSAI === newCDN.cdnIsSSAI && cdn.cdnContentType === newCDN.cdnContentType &&
                cdn.cdnProtectionType === newCDN.cdnProtectionType).length === 0) {
                cdns.push(newCDN);
                this.setState({cdns});
            } else {
                this.props.toast("Error", "The CDN already exists within this mapping.");
            }
        }
    };

    deleteCDN = () => this.setState({cdns: this.state.cdns.filter((cdn, index) => index !== this.state.cdnIndex)});

    editCDN = () => {
        if (["", null, "null", undefined, "none"].includes(this.state.cdnProtectionType)) {
            this.setState({cdnProtectionType: null});
        }

        if (this.state.cdnType && this.state.cdnUrl) {
            let cdns = this.state.cdns;
            cdns[this.state.cdnIndex] = {
                cdnType: this.state.cdnType,
                cdnUrl: this.state.cdnUrl,
                cdnWeight: parseInt(this.state.cdnWeight),
                cdnPriority: parseInt(this.state.cdnPriority),
                cdnIsSSAI: this.state.cdnIsSSAI,
                cdnContentType: this.state.cdnContentType,
                cdnProtectionType: this.state.cdnProtectionType,
                cdnIsEnabled: this.state.cdnIsEnabled,
                cdnCleanupRegex: this.state.cdnCleanupRegex
            };
            this.setState({cdns});
        }
    };

    changeSelectedOrigin = selectedOrigin => {
        this.setState({
            selectedOrigin: selectedOrigin,
            selectedCDN: {},
            action: "Modify",
            type: "Origin",
            originType: selectedOrigin.originType,
            originUrl: selectedOrigin.originUrl,
            originPriority: selectedOrigin.originPriority,
            originIsEnabled: selectedOrigin.originIsEnabled,
            originCleanupRegex: selectedOrigin.originCleanupRegex || ""
        });
    };

    changeSelectedCDN = selectedCDN => {
        this.setState({
            selectedCDN: selectedCDN,
            selectedOrigin: {},
            action: "Modify",
            type: "CDN",
            cdnType: selectedCDN.cdnType,
            cdnUrl: selectedCDN.cdnUrl,
            cdnWeight: selectedCDN.cdnWeight,
            cdnPriority: selectedCDN.cdnPriority,
            cdnIsSSAI: selectedCDN.cdnIsSSAI,
            cdnProtectionType: selectedCDN.cdnProtectionType,
            cdnContentType: selectedCDN.cdnContentType,
            cdnIsEnabled: selectedCDN.cdnIsEnabled,
            cdnCleanupRegex: selectedCDN.cdnCleanupRegex || "" });
    };

    changeAction = action => this.setState({action});

    render() {
        let that = this;
        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column width={11} className='masterContainer'>
                        <Grid>
                            <Grid.Column floated='right' width={16}>
                                <Container fluid textAlign='right' className='actionBarContainer'>
                                    <Button.Group size="mini">
                                        <Button onClick={this.loadData} icon="refresh" content="Refresh"/>
                                        <Button.Or text=""/>
                                        <ContingentButton
                                            onClick={this.onClickNew}
                                            icon="plus"
                                            content="Create New"
                                            service={this.props.service}
                                            module={this.props.module}
                                            scope="all"
                                            allPermissions={this.props.permissions}
                                            user={this.props.user}
                                        />
                                    </Button.Group>
                                </Container>
                            </Grid.Column>
                        </Grid>
                        {!this.state.data.error ?
                            <ReactTable
                                columns={[
                                    { accessor: 'mapping_id', show: false },
                                    { Header: "Brand", accessor: "brand" },
                                    { Header: "Description", accessor: "description" }
                                ]}
                                defaultSorted={[{id: "brand", asc: true }]}
                                data={this.state.data}
                                expanded={this.state.expanded}
                                onExpandedChange={(expanded, index) => {
                                    this.setState({expanded: {[index]: !this.state.expanded[index]}});
                                }}
                                loading={this.state.loading}
                                filterable
                                defaultFilterMethod={(filter, rows) => rows[filter.id].toLowerCase().includes(filter.value.toLowerCase())}
                                className="-striped -highlight"
                                getTrProps={(state, rowInfo, column, instance) => {
                                    return {
                                        onClick(event, handleOriginal) {
                                            that.setState({selectedKey: rowInfo.row.mapping_id, newClicked: false, action: "Modify", type: "Mapping"});
                                        },
                                        style: {
                                            background: rowInfo && rowInfo.row && that.state.selectedKey === rowInfo.row.mapping_id ? 'rgba(65, 83, 175, .5)' : '',
                                        }
                                    }
                                }}
                                style={{height: '75vh'}}
                                SubComponent={row => {
                                    return (
                                        <Container fluid>
                                            <URLTableGrid
                                                name="Origins"
                                                changeAction={this.changeAction}
                                                data={row.original.origins}
                                                addItem={this.addOriginClicked}
                                                deleteItem={this.deleteOriginClicked}
                                                keyName="originUrl"
                                                loading={this.state.loading}
                                                selectedItem={this.state.selectedOrigin}
                                                changeSelectedItem={this.changeSelectedOrigin}
                                                changeItemIndex={this.changeOriginIndex}
                                                service={this.props.service}
                                                module={this.props.module}
                                                permissions={this.props.permissions}
                                                user={this.props.user}
                                                columns={[
                                                    {Header: "Origin Type", accessor: "originType"},
                                                    {Header: "Origin URL", accessor: "originUrl"},
                                                    {Header: "Origin Priority", accessor: "originPriority"},
                                                    {Header: "Enabled", accessor: "originIsEnabled",
                                                        Cell: row => <span>{row.value? row.value.toString() : "false"}</span>,
                                                        filterMethod: (filter, rows) => (rows[filter.id] ? rows[filter.id].toString() : "false").toLowerCase().includes(filter.value.toLowerCase())
                                                    }
                                                ]}
                                            />
                                            <URLTableGrid
                                                name="CDNs"
                                                changeAction={this.changeAction}
                                                data={row.original.cdns}
                                                addItem={this.addCDNClicked}
                                                deleteItem={this.deleteCDNClicked}
                                                loading={this.state.loading}
                                                keyName="cdnUrl"
                                                selectedItem={this.state.selectedCDN}
                                                changeSelectedItem={this.changeSelectedCDN}
                                                changeItemIndex={this.changeCdnIndex}
                                                service={this.props.service}
                                                module={this.props.module}
                                                permissions={this.props.permissions}
                                                user={this.props.user}
                                                columns={[
                                                    {Header: "CDN Type", accessor: "cdnType"},
                                                    {Header: "CDN URL", accessor: "cdnUrl"},
                                                    {Header: "CDN Priority", accessor: "cdnPriority"},
                                                    {Header: "SSAI",
                                                        accessor: "cdnIsSSAI",
                                                        Cell: row => <span>{row.value? row.value.toString() : "false"}</span>,
                                                        filterMethod: (filter, rows) => (rows[filter.id] ? rows[filter.id].toString() : "false").toLowerCase().includes(filter.value.toLowerCase())
                                                    },
                                                    {Header: "CDN Protection Type", accessor: "cdnProtectionType"},
                                                    {Header: "Content Type", accessor: "cdnContentType"},
                                                    {Header: "Enabled", accessor: "cdnIsEnabled",
                                                        Cell: row => <span>{row.value? row.value.toString() : "false"}</span>,
                                                        filterMethod: (filter, rows) => (rows[filter.id] ? rows[filter.id].toString() : "false").toLowerCase().includes(filter.value.toLowerCase())
                                                    }
                                                ]}
                                            />
                                        </Container>)
                                    }
                                }
                            /> : ""
                        }
                    </Grid.Column>
                    <Grid.Column width={5} className='detailsContainer'>
                        <OriginCDNForm
                            action={this.state.action}
                            type={this.state.type}
                            selectedKey={this.state.selectedKey}
                            newClicked={this.state.newClicked}
                            onClickSave={this.onClickSave}
                            brand={this.state.brand}
                            origins={this.state.origins}
                            cdns={this.state.cdns}
                            description={this.state.description}
                            changeDescription={this.changeDescription}
                            changeBrand={this.changeBrand}
                            changeCdnContentType={this.changeCdnContentType}
                            changeCdnIsSSAI={this.changeCdnIsSSAI}
                            provider={Provider}
                            changeOrigins={this.changeOrigins}
                            changeCDNs={this.changeCDNs}
                            cdnType={this.state.cdnType}
                            cdnUrl={this.state.cdnUrl}
                            cdnWeight={this.state.cdnWeight}
                            cdnPriority={this.state.cdnPriority}
                            cdnIsEnabled={this.state.cdnIsEnabled}
                            cdnCleanupRegex={this.state.cdnCleanupRegex}
                            originType={this.state.originType}
                            originUrl={this.state.originUrl}
                            originPriority={this.state.originPriority}
                            originIsEnabled={this.state.originIsEnabled}
                            originCleanupRegex={this.state.originCleanupRegex}
                            changeOriginType={this.changeOriginType}
                            changeOriginUrl={this.changeOriginUrl}
                            changeOriginPriority={this.changeOriginPriority}
                            changeOriginIsEnabled={this.changeOriginIsEnabled}
                            changeOriginCleanupRegex={this.changeOriginCleanupRegex}
                            changeCdnType={this.changeCdnType}
                            changeCdnUrl={this.changeCdnUrl}
                            changeCdnWeight={this.changeCdnWeight}
                            changeCdnPriority={this.changeCdnPriority}
                            changeCdnIsEnabled={this.changeCdnIsEnabled}
                            cdnContentType={this.state.cdnContentType}
                            cdnProtectionType={this.state.cdnProtectionType}
                            changeCdnProtectionType={this.changeCdnProtectionType}
                            changeCdnCleanupRegex={this.changeCdnCleanupRegex}
                            cdnIsSSAI={this.state.cdnIsSSAI}
                            user={this.props.user}
                            service={this.props.service}
                            module={this.props.module}
                            permissions={this.props.permissions}
                            userCanEdit={this.state.userCanEdit}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}

OriginCDNMapperMasterDetailView.propTypes = {
    user: PropTypes.object.isRequired,
    toast: PropTypes.func.isRequired,
    service: PropTypes.string.isRequired,
    module: PropTypes.string.isRequired,
    permissions: PropTypes.array.isRequired
};
